"use strict";

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let usedTime = "";
let expirationTime = "";
const getExpirationTime = () => {
    if (localStorage.getItem("expired")) {
        usedTime = new Date();
        const date = localStorage.getItem("expired");
        // const date = "2020-12-17 16:00:00";
        expirationTime = date.split(/[- :]/);
        expirationTime = new Date(expirationTime[0], expirationTime[1]-1, expirationTime[2], expirationTime[3], expirationTime[4], expirationTime[5]);
    }
};

const returnRoot = (to, from, next) => {
    getExpirationTime();
    if (localStorage.getItem("token") === null || expirationTime < usedTime) {
        return next({ "name": "teams_logout" });
    }
    return next({ "name": "search" });
};

const returnLogout = (to, from, next) => {
    getExpirationTime();
    if (localStorage.getItem("token") === null || expirationTime < usedTime) {
        return next({ "name": "offices_logout" });
    } else if (localStorage.getItem("role") === "1") {
        return next({ "name": "search" });
    }
    return next();
};

const returnTeamsLogout = (to, from, next) => {
    getExpirationTime();
    if (localStorage.getItem("token") === null || expirationTime < usedTime) {
        if (localStorage.getItem("role") === "0") {
            return next({ "name": "offices_logout" });
        } else {
            return next({ "name": "teams_logout" });
        }
    }
    return next();
};

const router = new Router({
    "mode": "history",
    "base": process.env.BASE_URL,
    "routes": [
        {
            "path": "/",
            "name": "home",
            "redirect": { "name": "search" },
        },
        {
            "path": "/teams",
            "component": () => import("./layouts/Dashboard.vue"),
            "children": [
                {
                    "path": "",
                    "name": "teams_list",
                    "component": () => import("./views/TeamsList.vue"),
                    "beforeEnter": returnLogout,
                },
                {
                    "path": "create",
                    "name": "teams_create",
                    "component": () => import("./views/TeamsDetail.vue"),
                    "beforeEnter": returnLogout,
                },
                {
                    "path": "update/:id",
                    "name": "teams_update",
                    "component": () => import("./views/TeamsDetail.vue"),
                    "beforeEnter": returnLogout,
                    "props": true,
                },
            ],
        },
        {
            "path": "/similar_keywords",
            "component": () => import("./layouts/Dashboard.vue"),
            "children": [
                {
                    "path": "",
                    "name": "similar_keywords_list",
                    "component": () => import("./views/SimilarKeywordsList.vue"),
                    "beforeEnter": returnLogout,
                },
                {
                    "path": "create",
                    "name": "similar_keywords_create",
                    "component": () => import("./views/SimilarKeywordsDetail.vue"),
                    "beforeEnter": returnLogout,
                },
                {
                    "path": "update/:id",
                    "name": "similar_keywords_update",
                    "component": () => import("./views/SimilarKeywordsDetail.vue"),
                    "beforeEnter": returnLogout,
                    "props": true,
                },
            ],
        },
        {
            "path": "/search",
            "component": () => import("./layouts/Dashboard.vue"),
            "children": [
                {
                    "path": "",
                    "name": "search",
                    "component": () => import("./views/SupportDataSearch.vue"),
                    "beforeEnter": returnTeamsLogout,
                },
            ],
        },
        {
            "path": "/support_data",
            "component": () => import("./layouts/Dashboard.vue"),
            "children": [
                {
                    "path": ":id/:creator",
                    "name": "support_data",
                    "component": () => import("./views/SupportDataDetail.vue"),
                    "beforeEnter": returnTeamsLogout,
                    "props": true,
                },
            ],
        },
        {
            "path": "/my_page",
            "component": () => import("./layouts/Dashboard.vue"),
            "children": [
                {
                    "path": "",
                    "name": "my_page",
                    "component": () => import("./views/MyPage.vue"),
                    "beforeEnter": returnTeamsLogout,
                },
            ],
        },
        {
            "path": "/offices/logout",
            "name": "offices_logout",
            "component": () => import("./layouts/OfficesLogOut.vue"),
            "meta": {
                "title": "Offices Log Out",
            },
        },
        {
            "path": "/offices/login",
            "name": "offices_login",
            "component": () => import("./layouts/OfficesLogIn.vue"),
            "meta": {
                "title": "Offices Log In",
            },
        },
        {
            "path": "/teams/logout",
            "name": "teams_logout",
            "component": () => import("./layouts/TeamsLogOut.vue"),
            "meta": {
                "title": "Teams Log Out",
            },
        },
        {
            "path": "/teams/login",
            "name": "teams_login",
            "component": () => import("./layouts/TeamsLogIn.vue"),
            "meta": {
                "title": "Teams Log In",
            },
        },
        {
            "path": "*",
            "beforeEnter": returnRoot,
        },
    ],
    scrollBehavior() {
        return {
            "x": 0,
            "y": 0,
        };
    },
});

export default router;
